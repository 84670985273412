@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.likes {
  &__button {
    display: flex;
    margin-top: 0.5rem;
    width: 20rem;
    justify-content: flex-end;
    flex-direction: row;
  }
  &__button-thumb {
    height: 30px;
    @include tablet {
      height: 30px;
    }
  }
  &__button-counter {
    align-self: center;
    font-size: 1rem;
    font-weight: 200;
  }
}
