@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  @include tablet {
    width: 50vw;
    margin-left: 2rem;
    max-width: 500px;
  }
  &__required {
    font-size: 0.95rem;
    letter-spacing: 2px;
    color: rgb(185, 185, 185);
  }
  &__label {
    letter-spacing: 1.5px;
  }
  &__input {
    border: 2px solid;
    padding: 15px;
    margin: 0.5rem 0;
    font-size: 1rem;
    transition: background-color 0.3s ease; // Smooth transition on background color change
    &--darken {
      &:hover:not(:focus) {
        background-color: rgba(255, 255, 255, 0.605); // Darken the background on hover
      }
    }
  }
  &__success {
    align-self: center;
    font-size: 1.2rem;
  }
  &__text-area {
    min-height: 150px;
    max-width: 100%;
    margin-bottom: 1rem;
  }
  &__button {
    height: 50px;
    margin-top: 1rem;
    width: 100px;
    font-family: Oswald;
    color: white;
    font-size: 1rem;
    background-color: black;
    border: none;
    border: rgba(255, 255, 255, 0.394) solid 2px;

    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.13); // Darken the background on hover
    }
  }
  &__tube-spinner {
    margin-top: 1rem;
    height: 50px;
  }
  &__error-message {
    color: white;
    background-color: rgba(255, 0, 0, 0.585);
    padding: 0.5rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include tablet {
    flex-direction: row;
  }
}
.text {
  margin-bottom: 5rem;
  &__paragraph {
    font-size: 1.5rem;
    align-content: center;
    @include tablet {
      max-width: 500px;
      margin-top: 2rem;
    }
  }
}
.gform {
  display: flex;
  flex-direction: column;
  width: 100vw;
}
