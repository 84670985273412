@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.wedding-title {
  text-align: center;
  font-weight: lighter;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}
