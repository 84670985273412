@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.header {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  @include desktop {
    margin: auto;
    margin-top: 0rem;
    max-width: 95%;
    padding-bottom: 2rem;
  }
}
.header-nav {
  display: flex;
  &__logo-text {
    display: flex;
    flex-direction: row;
    @include desktop {
    }
  }
  &__title {
    text-transform: uppercase;
    transition: color 0.3s ease;
    font-size: 1.5rem;
    letter-spacing: 5px;
    width: auto;
    max-width: 15rem;
    min-width: 0.5625rem;
    padding-right: 2.7rem;
    @include tablet {
      font-size: 2rem;
    }
  }

  &__full-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
  &__link {
    margin-left: 0.7rem;
    font-size: 1.15rem;
    transition: color 0.3s ease;
    padding-bottom: 0.25rem !important;

    &--active {
      border-bottom: 2px solid white;
    }
    &--inactive {
      color: white;
    }
    &:hover {
      color: #8f8f8f;
    }
  }
}
