@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.not-found {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  &__image {
    width: 250px;
    margin-bottom: 1rem;
    @include tablet {
      width: 500px;
    }
  }
}
