@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.still-life {
  width: 100%;
  // max-width: 1500px;
  margin: auto;
  margin-bottom: 2rem;
  &__title {
    text-align: center;
    font-weight: lighter;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
  }
}
