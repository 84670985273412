@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.react-photo-album {
  margin-bottom: 2rem;
  @include tablet {
    margin-top: 2rem;
  }
}
