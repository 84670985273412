@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.videos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 2500px;
  margin: auto;
  &__vid {
    margin: 1rem 1rem;
    height: auto !important;
    aspect-ratio: 16/9;
  }
  &__title {
    text-align: center;
    font-weight: lighter;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
  }
}

.videos-title {
  text-align: center;
  font-weight: lighter;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}
