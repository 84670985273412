@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.landing-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing {
  text-align: left;
  margin: 4rem 0rem 6rem 0rem;
  font-weight: 100;
  text-transform: lowercase;
  font-size: 1.25rem;
  @include tablet {
    font-size: 1.5rem;
  }

  &__start {
    text-transform: capitalize;
    font-size: 1.5rem;
    @include tablet {
      font-size: 2rem;
    }
  }
  &__middle {
    text-transform: lowercase;
    font-size: 1.5rem;
    @include tablet {
      font-size: 2rem;
    }
  }
}
