@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: end;
  padding: 1rem;
  border-top: 1px solid white;
  @include desktop {
    padding: 4rem;
  }
  &__icon {
    height: 50px;
    margin: 1rem;
    transition: filter 0.3s; // Add a transition for a smooth effect

    &:hover {
      filter: brightness(70%); // Darken the icon on hover
    }
  }
}
