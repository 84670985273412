@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/Oswald-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/Oswald-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/Oswald-ExtraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/Oswald-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
